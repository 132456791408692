import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {v4} from 'uuid';
import {db} from '../../lib/henobase.config';
import {
	selectedProject,
	selectUserData,
	setSelectedProject,
} from '../../redux/slice/userSlice';

function Home(props) {
	const project = useSelector(selectedProject);

	const [projectName, setProjectName] = React.useState('');

	const data = useSelector(selectUserData);
	const dispatch = useDispatch();

	async function createProject() {
		if (projectName !== '') {
			let newDoc = {
				dbID: v4(),
				projectName: projectName,
				createdAt: new Date(),
				updatedAt: new Date(),
				apiKey: v4(),
				userID: data._id,
			};

			let res = await db.collection('projects').insert(newDoc);

			setProjectName('');
			dispatch(setSelectedProject(res));
		}
	}

	if (typeof project._id === 'undefined') {
		return (
			<div className="w-full h-full flex flex-col bg-gray-100">
				<div className="bg-accent bg-opacity-75 text-white shadow-lg p-6">
					<h1 className="text-2xl font-bold mb-2">Create a new project</h1>
				</div>
				<div className="bg-white shadow-lg m-12 border border-gray-200 rounded-lg p-6">
					<div>
						<label>
							<p className="text-base font-semibold">Project name:</p>
							<input
								type="text"
								placeholder="Project name"
								value={projectName}
								onChange={(e) => setProjectName(e.target.value)}
								className=" bg-white border border-gray-300 rounded-xl my-2 py-2 px-4"
							/>
						</label>
						<button
							className="bg-primary-default hover:bg-primary-light active:bg-primary-dark ml-4 text-white font-bold py-2 px-4 rounded-xl my-2"
							onClick={createProject}
						>
							Create
						</button>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="w-full h-full flex flex-col bg-gray-100">
				<div className="bg-accent bg-opacity-75 text-white shadow-lg p-6">
					<h1 className="text-2xl font-bold mb-2">{project.projectName}</h1>
				</div>
				<div className="bg-white shadow-lg m-12 border border-gray-200 rounded-lg p-6">
					{project._id && (
						<>
							<table>
								<thead>
									<td>
										<h2 className="text-xl font-semibold mb-2">Connection</h2>
									</td>
									<td></td>
								</thead>
								<tr>
									<td>connectionURL</td>
									<td>
										<input
											className="w-[57ch] border border-gray-300 rounded-md px-2 py-1 ml-4"
											type={'text'}
											minLength={'50'}
											value={'https://api.henomedia.de/api/v1/' + project.dbID}
											readOnly
										/>
									</td>
								</tr>
								<tr>
									<td>apiKey:</td>
									<td>
										<input
											className="w-[33ch] border border-gray-300 rounded-md px-2 py-1 ml-4"
											type={'text'}
											minLength={'50'}
											value={project.apiKey}
											readOnly
										/>
									</td>
								</tr>
							</table>
						</>
					)}
				</div>
			</div>
		);
	}
}

export default Home;
