import React from 'react';
import {useSelector} from 'react-redux';
import {db} from '../../lib/henobase.config';
import {selectedProject} from '../../redux/slice/userSlice';

function User(props) {
	const project = useSelector(selectedProject);

	const [allUsers, setAllUsers] = React.useState([]);

	React.useEffect(() => {
		getData();
	}, [project]);

	async function getData() {
		const users = await db.collection('users').getAll();

		setAllUsers(users);
		console.log(users);
	}

	if (typeof project._id === 'undefined') {
		return (
			<div className="w-full h-full flex flex-col bg-gray-100">
				<div className="bg-white shadow-lg m-12 border border-gray-200 rounded-lg p-6">
					<h2 className="text-xl font-bold">Select a project first.</h2>
				</div>
			</div>
		);
	} else {
		return (
			<div className="w-full h-full flex flex-col bg-gray-100">
				<div className="bg-white shadow-lg m-12 border border-gray-200 rounded-lg p-6">
					<h2 className="text-xl font-bold mb-2">All user</h2>
					{}
				</div>
			</div>
		);
	}
}

export default User;
