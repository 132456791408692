import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {auth} from '../../lib/henobase.config';
import {setUserData} from '../../redux/slice/userSlice';

import HeNoBaseLogo from '../../assets/logo/HeNoBaseLogo.png';

function Forgot(props) {
	const navigate = useNavigate();

	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');

	async function handleLogin() {
		await auth.login(email, password);
	}

	return (
		<div className="w-1/2">
			<img src={HeNoBaseLogo} alt="HeNoBase" className="w-3/4 mb-12" />
			<h1 className="text-4xl font-semibold">Forgot password</h1>
			<p className="text-lg font-light">Please enter your email address</p>
			<div className="my-8" />
			<>
				<label>
					<div className="text-sm font-normal">E-Mail</div>
					<input
						type="text"
						placeholder="E-Mail"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className="w-full bg-white border border-gray-300 rounded-xl my-2 py-2 px-4"
					/>
				</label>

				<div className="my-5" />
				<button
					className="w-full text-xl bg-primary-default hover:bg-primary-light active:bg-primary-dark text-white font-semibold py-3 px-4 rounded-xl"
					onClick={() => {}}
				>
					Send Link
				</button>

				<div className="my-5" />
				<div className="text-base font-extralight underline cursor-pointer">
					<span onClick={() => navigate(-1)}>Go Back</span>
				</div>
			</>
		</div>
	);
}

export default Forgot;
