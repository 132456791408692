import {createSlice} from '@reduxjs/toolkit';

export const userSlice = createSlice({
	name: 'counter',
	initialState: {
		userData: {},
		isLoggedIn: false,
		selectedProject: {},
	},
	reducers: {
		setUserData: (state, action) => {
			state.userData = action.payload;

			state.isLoggedIn = false;

			if (action.payload._id) {
				state.isLoggedIn = true;
			}
		},
		setSelectedProject: (state, action) => {
			state.selectedProject = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {setUserData, setSelectedProject} = userSlice.actions;

// Select state
export const selectUserData = (state) => state.user.userData;
export const selectIsLoggedIn = (state) => state.user.isLoggedIn;
export const selectedProject = (state) => state.user.selectedProject;

export default userSlice.reducer;
