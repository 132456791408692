import React from 'react';
import {Outlet} from 'react-router-dom';
import Navbar from '../../ui/navbar';

function AuthenticatedIndex(props) {
	return (
		<div className="w-full h-full flex bg-gray-50">
			<Navbar />
			<Outlet />
		</div>
	);
}

export default AuthenticatedIndex;
