import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {auth, db} from '../lib/henobase.config';
import {
	selectedProject,
	selectUserData,
	setSelectedProject,
} from '../redux/slice/userSlice';

import HeNoBaseLogo from '../assets/logo/HeNoBaseLogoLight.png';

function Navbar(props) {
	const user = auth.getUser();

	const project = useSelector(selectedProject);

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const navItems = [
		{
			name: 'Dashboard',
			path: '/',
		},
		{
			name: 'Users',
			path: '/user',
		},
		{
			name: 'DB',
			path: '/db',
		},
	];

	const [allProjects, setAllProjects] = React.useState([]);

	React.useEffect(() => {
		getData();
	}, [project]);

	async function getData() {
		let res = await db
			.collection('projects')
			.where({userID: user._id})
			.getAll();
		setAllProjects(res);
	}

	return (
		<div className="bg-accent h-full w-fit py-24 text-white flex flex-col justify-between items-center">
			<div className="px-12">
				<img src={HeNoBaseLogo} alt="HeNoBase" className="w-[250px]" />
			</div>
			<div className="w-full">
				{navItems.map((item) => (
					<div
						key={item.path}
						className="font-normal w-full py-4 px-8 my-0.5 bg-white bg-opacity-25 cursor-pointer hover:bg-opacity-30 active:bg-opacity-50"
						onClick={() => navigate(item.path)}
					>
						{item.name}
					</div>
				))}
			</div>
			<div className="px-12">
				<select
					onChange={(e) =>
						dispatch(
							setSelectedProject(
								allProjects.find((p) => p._id === e.target.value) || {}
							)
						)
					}
					value={project._id}
					className="bg-transparent w-full p-0 m-0 cursor-pointer "
				>
					<option value={''} selected>
						New Project
					</option>
					{allProjects.map((project) => (
						<option value={project._id} key={project._id}>
							{project.projectName}
						</option>
					))}
				</select>
				<div>{user.username}</div>
			</div>
		</div>
	);
}

export default Navbar;
