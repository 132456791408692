import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {auth} from './lib/henobase.config';
import {selectIsLoggedIn, setUserData} from './redux/slice/userSlice';
import AuthenticatedIndex from './screens/authenticated';
import DB from './screens/authenticated/db';
import Home from './screens/authenticated/home';
import User from './screens/authenticated/user';
import LoginIndex from './screens/login';
import Forgot from './screens/login/forgot';
import Login from './screens/login/login';
import Register from './screens/login/register';

function App(props) {
	const dispatch = useDispatch();

	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			setIsLoggedIn(user ? true : false);
			dispatch(setUserData(user));
		});
	}, []);

	const [isLoggedIn, setIsLoggedIn] = React.useState(false);

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={isLoggedIn ? <AuthenticatedIndex /> : <LoginIndex />}
				>
					{!isLoggedIn && (
						<>
							<Route path="" element={<Login />} />
							<Route path="register" element={<Register />} />
							<Route path="forgot" element={<Forgot />} />
						</>
					)}
					{isLoggedIn && (
						<>
							<Route path="" element={<Home />} />
							<Route path="/user" element={<User />} />
							<Route path="/db" element={<DB />} />
						</>
					)}
					<Route path="*" element={<Navigate to="/" />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
