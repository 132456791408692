import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {auth} from '../../lib/henobase.config';
import {setUserData} from '../../redux/slice/userSlice';

import HeNoBaseLogo from '../../assets/logo/HeNoBaseLogo.png';

function Login(props) {
	const navigate = useNavigate();

	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');

	async function handleLogin() {
		await auth.login(email, password);
	}

	return (
		<div className="w-1/2">
			<img src={HeNoBaseLogo} alt="HeNoBase" className="w-3/4 mb-12" />
			<h1 className="text-4xl font-semibold">Login</h1>
			<p className="text-lg font-light">Please login to access the app.</p>
			<>
				<div className="w-full flex justify-between my-6">
					<div className="flex w-1/3">
						<div className="w-full h-1/2 border-b border-gray-300"></div>
					</div>
					<div>
						<div className="text-sm font-extralight">Sign in with E-Mail</div>
					</div>
					<div className="flex w-1/3">
						<div className="w-full h-1/2 border-b border-gray-300"></div>
					</div>
				</div>
				<label>
					<div className="text-sm font-normal">E-Mail</div>
					<input
						type="text"
						placeholder="E-Mail"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className="w-full bg-white border border-gray-300 rounded-xl my-2 py-2 px-4"
					/>
				</label>
				<div className="my-3" />
				<label>
					<div className="text-sm font-normal">Password</div>
					<input
						type="password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						className="w-full bg-white border border-gray-300 rounded-xl my-2 py-2 px-4"
					/>
				</label>

				<div className="my-3" />
				<div className="w-full text-right underline text-base hover:text-gray-700 active:text-gray-300 cursor-pointer">
					<span onClick={() => navigate('/forgot')}>Forgot Password?</span>
				</div>
				<div className="my-5" />
				<button
					className="w-full text-xl bg-primary-default hover:bg-primary-light active:bg-primary-dark text-white font-semibold py-3 px-4 rounded-xl"
					onClick={() => handleLogin()}
				>
					Login
				</button>

				<div className="my-5" />
				<div className="text-base font-extralight">
					Don't have an account?{' '}
					<span
						className="underline text-base text-primary-default hover:text-primary-light active:text-primary-dark cursor-pointer"
						onClick={() => {}}
					>
						Create an Account
					</span>
				</div>
			</>
		</div>
	);
}

export default Login;
