import HeNoBaseConnection from 'henobase';

let henobaseConfig = {
	apiKey: '7fa5f2bf-970c-46e6-8fe0-4ddad1fe5842',
	connectionURL:
		'https://api.henobase.de/api/v1/bc245e5f-2d2c-405b-bca9-fd58777e6925',
};

const HeNoBase = new HeNoBaseConnection(henobaseConfig);

const auth = HeNoBase.auth();
const db = HeNoBase.db();

export {auth, db};
