import React from 'react';
import {Outlet} from 'react-router-dom';

import HeNoBaseLogoLight from '../../assets/logo/HeNoBaseLogoLight.png';

function LoginIndex(props) {
	return (
		<div className="w-full h-full flex items-center justify-center bg-gray-50">
			<div className="xl:w-1/2 lg:w-2/3 w-full h-full flex items-center justify-center shadow-2xl z-10">
				<Outlet />
			</div>
			<div className="xl:w-1/2 lg:w-1/3 w-[0px] h-full flex items-center justify-center bg-accent z-0"></div>
		</div>
	);
}

export default LoginIndex;
